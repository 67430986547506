import ApiMakerConfig from "@kaspernj/api-maker/build/config"
import {callbacksHandler} from "on-location-changed/build/callbacks-handler"
import currenciesCollection from "shared/currencies-collection"
import history from "@kaspernj/api-maker/build/history"
import Modal from "components/modal"
/* eslint-disable sort-imports */

// Set global.applicationHistory so that on-location-changed will listen on it
callbacksHandler.connectReactRouterHistory(history)

// Use our own logging tool to log errors when testing
import "shared/error-logger"

// API maker
ApiMakerConfig.setCurrenciesCollection(currenciesCollection)
ApiMakerConfig.setHistory(history)
ApiMakerConfig.setModal(Modal)

// Stylesheets
require.context("..", true, /\/(.+)\.(s|)css$/)
require.context("@kaspernj/api-maker/src", true, /\/(.+)\.(s|)css$/)

// React layout and application
import "shared/action-cable-logging"
import "shared/react-native-vector-icons"
import "shared/source-maps"
import "shared/time-zone-offset"
import "components/play"
import "controllers"
import "stylesheets/application"
import "react-toggle/style"
