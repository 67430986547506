import {PortalHost, PortalProvider} from "conjointment"
import AppHistory from "shared/history"
import {callbacksHandler} from "on-location-changed/build/callbacks-handler"
import {createRoot} from "react-dom/client"
import {Container as FlashNotifications} from "flash-notifications"
import history from "@kaspernj/api-maker/build/history"
import {LoadingBlocker} from "components/layout/loading-blocker"
import Locales from "shared/locales"
import ModelsResponseReader from "@kaspernj/api-maker/build/models-response-reader"
import React from "react"
import Router from "@kaspernj/api-maker/build/router"
import RoutesPlay from "shared/routes-play"
import SessionStatusUpdater from "@kaspernj/api-maker/build/session-status-updater"
import {StyleSheet} from "react-native"
import {WithDefaultStyle} from "@kaspernj/api-maker/build/utils/default-style"
import WithLocationPath from "on-location-changed/build/with-location-path"

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const routeDefinitions = require("shared/route-definitions-play.json")

require("shared/devise")

// Set global.applicationHistory so that on-location-changed will listen on it
callbacksHandler.connectReactRouterHistory(history)

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  globalThis.currentUser = models[0]
}

globalThis.sessionStatusUpdater = new SessionStatusUpdater()
globalThis.sessionStatusUpdater.startTimeout()

const routesRequireContext = require.context("routes/play", true, /(.+)-route\.jsx$/)
const requireComponent = ({routeDefinition}) => routesRequireContext(`./${routeDefinition.component}-route.jsx`).default

const styles = StyleSheet.create({
  textDefault: {
    fontFamily: "Nunito"
  }
})

const defaultStyle = {
  Text: [styles.textDefault]
}

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <WithDefaultStyle style={defaultStyle}>
      <WithLocationPath>
        <PortalProvider>
          <PortalHost>
            <LoadingBlocker />
            <FlashNotifications />
            <Router
              history={AppHistory}
              locales={Locales.availableLocales()}
              notFoundComponent={LayoutNotFoundPage}
              requireComponent={requireComponent}
              routeDefinitions={routeDefinitions}
              routes={RoutesPlay}
            />
          </PortalHost>
        </PortalProvider>
      </WithLocationPath>
    </WithDefaultStyle>
  )
})
